<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/empresas" />
        </v-col>
      </v-row>
    </v-card-actions>
    <empresas-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import empresas from "@/api/empresas";

export default {
  components: {
    EmpresasCampos: () => import("./EmpresasCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
    };
  },

  methods: {
    async salvar(empresa) {
      this.carregando = true;
      this.validacaoFormulario = {};

      try {
        await empresas.inserir(empresa);

        this.$router.push("/empresas", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_adicionar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
